import Vue from 'vue';
import { loadProduct, syncListData, loadProductWithCache } from '@/repository/product.js';
import Tabs from '@/assets/config/tabs.json';
import BmlCommon from "@fibl/bml-common";
import checklist, { traverseToData } from './product/checklist.js';
import documents from './product/documents.js';
import display from './product/display.js';
import approvals from './product/approvals.js';
import history from './product/history.js';
import listingconfirmation from './product/listingconfirmation.js';
import notes from './product/notes.js';
import options from './product/options.js';
import presence from './product/presence';
import exports from './product/exports';
import flatten, { unflatten } from 'flat';
import EventBus from '@/util/eventbus.js';
import ChangeEmitter from '@/util/change-emitter.js';
import { getAttributesetByExternalId } from '@/repository/system.js';
import { deepSet, deepGet } from '@/util/field-helper.js';

const modules = {
	checklist,
	documents,
	display,
	approvals,
	notes,
	history,
	options,
	presence,
	exports,
	listingconfirmation
};

export const listOfPathsForDeepLinking = [
	'_components',
	'_risklabels'
];

export const doNotMergePaths = [
	'_documents',
	'_checklist',
	'_approval',
	'_notes',
	'_display',
	'_active',
	'_nameHistory',
	'basis-f2',
	'_companies',
	'_categoryTree',
	'_responsible',
	'_hobbygarden',
	'_hobbygardenTree',
	'_invoiceSpecialItems',
	'_linked',
	'activatedOn',
	'activatedBy',
	'deactivatedOn',
	'activatedBy',
	'deactivatedBy',
	'_preventPublicDisplay'
];

export const componentUnlinkableFields = [
	'secrecy',
	'printlabel'
];

const langSuffixMatch = /.[a-z]{2}_[A-Z]{2}$/;
export function sanitizeLinkPath(p) {
	//removes language suffix from path
	if (langSuffixMatch.test(p)) {
		p = p.substr(0, p.length-6);
	}
	return p;
}
function makeComponentIndex(isBase) {
	return function(c, e) {
		c[e._id] = e;
		componentUnlinkableFields.forEach(f => {
			if (e[f]) e[f]._isBase = isBase;
		});
		return c;
	};
}
function flatMerge(base, list) {
	let flatBase = flatten(base);
	let flatList = flatten(list);
	let flatCombined = { ...flatBase, ...flatList };
	return unflatten(flatCombined, { overwrite: true });
}
function pruneComponentIndex(list, base) {
	Object.keys(list).forEach(key => {
		if (!(key in base)) {
			delete list[key];
		}
	});
}
function componentMerge(baseData, v) {
	let listComponentIndex = v.base && Array.isArray(v.base) ? v.base.reduce(makeComponentIndex(!baseData), {}) : {};
	let listAdditionalIndex = v.additional && Array.isArray(v.additional) ? v.additional.reduce(makeComponentIndex(!baseData), {}) : {};
	let mergeBase, mergedAdditional;
	if (baseData && baseData._components) {
		let baseComponentIndex = baseData._components.base && Array.isArray(baseData._components.base) ? baseData._components.base.reduce(makeComponentIndex(true), {}) : {};
		pruneComponentIndex(listComponentIndex, baseComponentIndex);
		mergeBase = flatMerge(baseComponentIndex, listComponentIndex);

		let baseAdditionalIndex = baseData._components.additional && Array.isArray(baseData._components.additional) ? baseData._components.additional.reduce(makeComponentIndex(true), {}) : {};
		pruneComponentIndex(listAdditionalIndex, baseAdditionalIndex);
		mergedAdditional = flatMerge(baseAdditionalIndex, listAdditionalIndex);
	} else {
		mergeBase = listComponentIndex;
		mergedAdditional = listAdditionalIndex;
	}
	return {
		base: mergeBase,
		additional: mergedAdditional
	};
}
export function mergeListAndBase(listData, baseData) {
	const merged = {};
	Object.keys(listData).forEach(key => {
		let v = JSON.parse(JSON.stringify(listData[key]));
		if (key === '_components') {
			merged[key] = componentMerge(baseData, v);
		} else if (listOfPathsForDeepLinking.includes(key) && baseData && baseData[key]) {
			merged[key] = flatMerge(baseData[key], v);
		} else {
			merged[key] = v;
		}
	});
	if (baseData) {
		Object.keys(baseData).forEach(key => {
			if (key in merged || doNotMergePaths.includes(key)) return;
			let v = JSON.parse(JSON.stringify(baseData[key]));
			if (key === '_components') {
				merged[key] = componentMerge(null, v);
			} else {
				merged[key] = v;
			}
		});
	}
	return merged;
}

function initializeWorkingCopy(baseData, listData, linked, out) {
	Object.keys(listData).forEach(key => {
		let v = JSON.parse(JSON.stringify(listData[key]));
		if (key === '_components') {
			out[key] = componentMerge(baseData, v);
		} else if (listOfPathsForDeepLinking.includes(key) && baseData && baseData[key]) {
			out[key] = flatMerge(baseData[key], v);
		} else {
			out[key] = v;
		}
		if (linked && baseData && !doNotMergePaths.includes(key)) {
			if (listOfPathsForDeepLinking.includes(key)) {
				let flat;
				if (key === '_components') {
					flat = {};
					Object.entries(out[key].base).forEach(([id, row]) => {
						componentUnlinkableFields.forEach(f => {
							if (!(row[f] && row[f]._isBase)) {
								flat[`base.${id}.${f}`] = false;
							}
						});
					});
					Object.entries(out[key].additional).forEach(([id, row]) => {
						componentUnlinkableFields.forEach(f => {
							if (!(row[f] && row[f]._isBase)) {
								flat[`additional.${id}.${f}`] = false;
							}
						});
					});
				} else {
					flat = flatten(v);
				}
				Object.keys(flat).forEach(subkey => {
					let fullKey = sanitizeLinkPath(`${key}.${subkey}`);
					Vue.set(linked, fullKey, 'direct');
				});
			}
		}
	});
	if (baseData) {
		Object.keys(baseData).forEach(key => {
			if (key in out || doNotMergePaths.includes(key)) return;
			let v = JSON.parse(JSON.stringify(baseData[key]));
			if (key === '_components') {
				out[key] = componentMerge(null, v);
			} else {
				out[key] = v;
			}
		});
	}
}

export const emitter = new ChangeEmitter();

const state = {
	//all cached loaded lists
	loadedData: {},			//caches several lists of 1 product for quicker switching
	canLink: true,			//true if baseList is loaded
	baseData: {},			//data loaded for base list
	listData: {},			//data loaded for list
	workingCopy: {},		//idx of data by path
	fields: [],				//copy of available fields
	linked: {},				//idx of linked paths
	lists: [],				//lists available in current product
	allLists: [],			//copy of all available lists
	rootList: null,			// eu / base list
	currentList: null,		//currently open list
	rev: null,				// set to product data if revision was used
	revNo: null,
	workingCopyHasChanges: false,
	tabs: Tabs,
	init: false,
	currentProductId: '',
	fieldErrors: {},			//mirror to workingCopy but values are true if valid or a string if error
	attributesetId: null,
	patch: null,
	companySeeingRecipe: []
};

const getters = {

	getValue: (state) => (path) => {
		let parts = path.split('.');
		return deepGet(state.workingCopy, parts);
	},

	getLinked: (state) => (path) => {
		path = sanitizeLinkPath(path);
		if (!(path in state.linked)) {
			return state.baseData ? 'basis' : 'direct';
		}
		return state.linked[path];
	},

	getField: (state) => (id) => {
		if (typeof id !== 'string') throw new Error(`invalid path: ${JSON.stringify(id)}`);
		if (id.indexOf('.') > -1) id = id.substr(id.lastIndexOf('.') + 1, id.length);
		let def = state.fields.find(field => {
			return field.data.path === id;
		});

		if (!def) {
			console.warn(`no definition found for path: ${id}`);
		}

		return def;
	},

	extendSelection: (state, getters) => (workingData, item, flattened) => {
		let persistedItem = flattened.find(x => x.id === item);
		if (!persistedItem || !persistedItem.data.parent || persistedItem.data.parent === 'undefined') {
			// item is root, stop iteration
			return;
		}

		persistedItem = persistedItem.data;

		const parent = flattened.find(x => x.id === persistedItem.parent);
		if (parent) {
			workingData.push(persistedItem.parent);

			getters.extendSelection(workingData, parent, flattened);
		}
	},

	traverseSelectionTree: (state, getters, rootState, rootGetters) => (type, workingData) => {
		// flatten hierarchical tree and traverse array upwards
		let flattenArray = function flattenArray(arr) {
			const flattened = [];
			arr.forEach(item => {
				flattened.push(item);

				if (item.children && Array.isArray(item.children)) {
					flattened.push(...flattenArray(item.children));
				}
			});

			return flattened;
		};

		let addChildren = function addChildren(arr, parent) {
			if (!parent.children) {
				return;
			}

			for (const child of parent.children) {
				arr.push(child);
				addChildren(arr, child);
			}
		};

		let ordered = [];
		for (const entry of rootGetters[`${type}/roots`]) {
			ordered.push(entry);
			addChildren(ordered, entry);
		}

		let flattened = flattenArray(ordered);

		for (const item of workingData) {
			getters.extendSelection(workingData, item, flattened);
		}

		let ret = [];
		for (const element of ordered) {
			if (workingData.find(x => x === element.id)) {
				ret.push(element.id);
			}
		}

		return ret;
	},

	// initial recursive call
	getTotalSelection: (state, getters) => (type, workingData) => {
		const selection = getters.traverseSelectionTree(type, workingData);
		return new Set(selection);
	},

	getFieldDefinitions: (state, getters) => ({ displayKey, displayType, path }) => {
		let selectedCategories = new Set(state.workingCopy._category);
		let workingData = state.workingCopy._category ? JSON.parse(JSON.stringify(state.workingCopy._category)) : [];

		selectedCategories = getters.getTotalSelection('category', workingData);

		return state.fields.filter(field => {
			if (displayType === 'table' && path) {
				displayKey = getters.getField(path).id;
			}
			//check tab/table/special display
			if (field.data.displayKey !== displayKey || field.data.displayType !== displayType) return false;

			// check if there is an main category selected for the field
			let visibleInMainCategories = field.data.visibleInMainCategories || [];
			let visibleInAnyCategory = visibleInMainCategories.length > 0;

			if (visibleInAnyCategory) {
				// check if the corresponding main category was selected in the tree
				const overlap = visibleInMainCategories.find(x => selectedCategories.has(x));
				if (!overlap) return false;
			}

			//check for list
			if (field.data.visibleInLists.length > 0) {
				if (!field.data.visibleInLists.includes(state.currentList.id)) return false;
			}

			return true;
		}).sort((a, b) => {
			return a.data.sorting > b.data.sorting ? 1 : -1;
		});
	},

	isValid: (state) => {
		let flat = flatten(state.fieldErrors);
		for (let value of Object.values(flat)) {
			if (value !== true
				&& (!(Array.isArray(value) && value.length === 0))
				&& !(typeof value === 'object' && value && Object.keys(value).length === 0)
			) {
				return false;
			}
		}
		return true;
	},

	isFieldValid: (state) => ({ path, def=true }) => {
		let entry = deepGet(state.fieldErrors, path.split('.'));
		return entry === null ? def : entry === true;
	},

	isBaseList: (state) => {
		return !state.baseData;
	},

	getCurrentProductId: (state) => {
		return state.currentProductId;
	},

	getWorkingCopy: (state) => {
		return JSON.parse(JSON.stringify(state.workingCopy));
	},

	getCurrentListId: (state) => {
		return state.currentList ? state.currentList.id : '';
	},

	getCurrentMainCategoryId: (state, getters, rootState, rootGetters) => {
		let idx = state.workingCopy._categoryTree;
		if (!idx) return null;
		for (let id of idx) {
			let cat = rootGetters['category/byId'](id);
			if (!cat) continue;
			if (!cat.data.parent) return cat.id;
		}
		return null;
	},

	isListArchived: (state) => (listId) => {
		const listData = state.loadedData[listId];

		if (!listData) {
			return false;
		}

		return listData._archived;
	},

	canLinkField: (state, getters, rootState, rootGetters) => (fieldPath) => {
		let field = rootGetters['field/byPath'](fieldPath);
		if (!field) return true;
		if (field.data.displayKey === 'hobbygarden' && field.data.displayType === 'tab') return false;
		if (field.data.type === 'comment') return false; // decouple basis-f2
		return true;
	},

	referenceTime: (state) => {
		return state.rev ? new Date(state.rev.updated) : new Date()
	},

	isLocalChecklist: (state, getters, rootState, rootGetters) => (fieldPath) => {
		let clId = fieldPath.split('.')[1];
		let clEntry = rootGetters['checklist/byId'](clId);
		if (!clEntry) {
			console.warn('unknown checklist id: ', clId);
			return false;
		}
		if (clEntry.data.modus === 'reference') {
			// console.debug('modus is reference', getters.isBaseList, clId, clEntry.data.title.de_DE);
			return getters.isBaseList;
		}
		if (clEntry.data.listRestrict && clEntry.data.listRestrict.length) {
			let restricted = clEntry.data.listRestrict.includes(getters.getCurrentListId);
			// console.debug('cl included in list-restrict', restricted, clId, clEntry.data.title.de_DE);
			return restricted;
		}
		// console.debug('cl unrestricted', clId, clEntry.data.title.de_DE);
		return true;
	},

	getCurrentPatch: (state) => {
		return state.patch;
	},

	getCompanyWhichSeeRecipe: (state, getters, rootState, rootGetters) => {
		const baseList = state.baseData || state.listData;
		const responsibleOrg = baseList._responsible?.institution;
		if (responsibleOrg !== rootState.messagecenter.idFiblDE) return [];
		let companySeeingRecipe = [];
		let relevantCompaniesId = new Set();
		const product = new BmlCommon.ProductClass();
		product.fromData({listData: state.loadedData});
		const listKeys = Object.keys(product.listData);
		for(let i in listKeys) {
			product.listData[listKeys[i]]['_companies']?.forEach((data) => {
				relevantCompaniesId.add(data.company);
			})
		}
		for (let id of relevantCompaniesId.values()) {
			const comp = rootGetters['companylist/byId'](id);
			if(comp && product.companyHasAccessToRecipe(comp.id)) {
				companySeeingRecipe.push({id:comp.id, name: comp.data.baseData.name});
			}
		}
		return companySeeingRecipe;
	}
};

const actions = {

	async init({ state, commit, dispatch, rootGetters }) {
		commit('setCurrentRev', null);
		commit('setRevData', null);

		if (state.init) return;
		await Promise.all([
			dispatch('list/loadAll', { ifEmpty: true }, { root: true }),
			dispatch('field/loadAll', { ifEmpty: true }, { root: true }),
			dispatch('biostandard/loadAll', { ifEmpty: true }, { root: true }),
			dispatch('selectoptions/loadAll', { ifEmpty: true }, { root: true }),
			dispatch('category/loadAll', { ifEmpty: true }, { root: true }),
			dispatch('component/loadAll', { ifEmpty: true }, { root: true }),
			dispatch('hobbygarden/loadAll', { ifEmpty: true }, { root: true }),
			dispatch('checklist/loadAll', { ifEmpty: true }, { root: true }),
			dispatch('permit/loadAll', { ifEmpty: true }, { root: true }),
			dispatch('auth/org/loadAll', { ifEmpty: true }, { root: true })
		]);
		let baseList = rootGetters['list/baseDataList'];
		let allLists = rootGetters['auth/user/roles/getAvailableLists'];
		commit('setModuleInitialized', { root: baseList, lists: allLists });
	},

	async save({ state, commit, dispatch, getters }) {
		let wk = JSON.parse(JSON.stringify(state.workingCopy));
		await EventBus.emit('beforeProductSave', wk, state.currentProductId );
		let listSaveFlat = {};
		let flatState = flatten(wk);
		console.log('beforesave flag', flatState);
		Object.keys(flatState).forEach(key => {
			let baseKey = key.indexOf('.') > -1 ? key.substr(0, key.indexOf('.')) : key;
			const isRemoteChecklist = baseKey === '_checklist' && !getters.isLocalChecklist(key);
			let testkey = baseKey === '_components' ? key.substr(0, key.lastIndexOf('.')) : key;	//remove .de_de or .selectvalue from component paths
			if (state.canLink
				&& !(baseKey === '_components' && key.includes('_id'))
				&& (baseKey === '_components' && state.baseData && (getters.getLinked(testkey) !== 'direct' || !componentUnlinkableFields.find(field => testkey.includes(field))))
				&& (!doNotMergePaths.includes(baseKey) || isRemoteChecklist)
				&& getters.canLinkField(baseKey)) return;

			if (!state.baseData && !(baseKey in state.linked) && !listOfPathsForDeepLinking.includes(baseKey) && !doNotMergePaths.includes(baseKey)) {
				state.linked[baseKey] = 'direct';
			}
			listSaveFlat[key] = flatState[key];
		});
		if (Object.keys(listSaveFlat).length === 0) {
			console.log('list save empty?', { wk, listSaveFlat });
			throw new Error('refusing to save empty product');
		}
		let listSave = unflatten(listSaveFlat);
		await EventBus.emit('beforeProductSaveCleanup', listSave, state.currentProductId );
		// console.log({ wk, listSave, listSaveFlat });
		// return;
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId('products');
			commit('setAsId', { id: ec.id });
		}


		if (!listSave._linked) {
			listSave._linked = {};
		}

		for (const entry of Object.entries(state.linked)) {
			const key = entry[0];
			if (key.includes('.')) {
				continue;
			}

			listSave._linked[key] = entry[1];
		}
		if (listSave._categoryTree && !listSave._category) delete listSave._categoryTree;
		let savedData = await syncListData(state.currentProductId, state.currentList.id, listSave, state.attributesetId);
		// await EventBus.emit('afterProductSaved');	//not in use
		commit('setPatch', savedData.patch);
		if (!savedData.savedData) {
			commit('setWorkingCopyHasChanges', false);
			return;
		}
		let product = await loadProductWithCache(state.currentProductId);
		commit('setLoadedList', { full: product.data.listData });
		commit('productlist/updateProductList', { id: state.currentProductId, full: product.data.listData }, { root: true });
		dispatch('switchList', { list: state.currentList.id });
	},

	async openProduct({ state, commit, rootGetters }, { id, rev }) {
		if (!state.init) throw new Error('module is not initialized');

		const product = await loadProduct(id, rev);

		if (rev) {
			commit('setRevData', product);
		} else {
			commit('setRevData', null);
		}
		commit('setCurrentRev', product.rev);

		commit('productlist/addProduct', product, { root: true });
		const data = JSON.parse(JSON.stringify( product.data.listData ));

		const listIds = Object.keys(product.data.listData);

		let allLists = rootGetters['list/root'];
		// const baseList = rootGetters['list/baseDataList'];
		// let baseResponsible = data[baseList.id] ? data[baseList.id]._responsible : null;
		// allLists = allLists.filter(l => {
		// 	return l.data.isBasedata
		// 		|| rootGetters['auth/user/roles/isResponsibleForProductGiven']({
		// 			listResponsible: data[l.id] ? data[l.id]._responsible : null,
		// 			baseResponsible
		// 		});
		// });
		let lists = listIds.map(id => {
			return allLists.find(l => l.id === id);
		}).filter(l => !!l);
		if (lists.length === 0) {
			lists = [state.rootList];
		}
		commit('changeCurrentProduct', { id, lists });
		let list = null;

		for (const l of lists) {
			if (rootGetters['auth/user/roles/canViewList'](l)) {
				list = l;
				break;
			}
		}

		if (!list) {
			list = lists[0];
		}
		commit('setLoadedList', { full: data });
		commit('changeCurrentList', { list });
		commit('init', { listData: state.loadedData[list.id] });
		commit('setAllLists', { lists: allLists });
	},

	switchList({ state, commit, rootState }, { list }) {
		let listEntity = state.allLists.find(l => l.id === list);
		if (!listEntity) throw new Error(`invalid list: ${list}`);
		let fields = JSON.parse(JSON.stringify(rootState.field.fields));
		EventBus.emit('beforeSwitchList');
		commit('setFields', []);
		if (!(list in state.loadedData)) {
			commit('setLoadedList', { list: listEntity, data: {} });
		}
		commit('changeCurrentList', { list: listEntity });
		let base = state.rootList.id;
		if (base === state.currentList.id) {
			commit('init', { listData: state.loadedData[base] });
		} else {
			commit('init', { baseData: state.loadedData[base], listData: state.loadedData[state.currentList.id] });
		}
		setTimeout(() => {
			//this is used to clear all fields for a moment and then re-initialize them,
			//so the fields do not call updateLink and cause a workingCopy change
			commit('setFields', fields);
			EventBus.emit('afterSwitchList');
			commit('setWorkingCopyHasChanges', false);
		}, 0);

		commit('resetFieldErrors');
	},

	changeFieldValid({ commit, getters }, { path, valid, message, def=null }) {
		let stored = getters.isFieldValid({ path, def });
		if (stored !== valid) {
			commit('setFieldValid', { path, valid, message });
		}
	},

	registerFieldTest({ dispatch, getters }, { path, test, def=null }) {
		if (emitter.has(path)) {
			emitter.remove(path);
			//console.warn('listener already installed for path', { path });
		}
		function setStatus(path, valid) {
			let message;
			if (valid === true) {
				message = null;
				valid = true;
			} else if (valid === false) {
				message = 'Pflichtfeld';
				valid = false;
			} else if (typeof valid === 'string') {
				message = valid;
				valid = false;
			} else {
				return;
			}
			dispatch('changeFieldValid', { path, valid, message, def });
		}
		const opts = {
			get: p => getters.getValue(p),
			setStatus
		};
		emitter.on(path, (path, value) => {
			let valid = test(path, value, opts);
			setStatus(path, valid);
		});
	},

	updateLinked({ state, commit, rootGetters }, { path, linked, linkPath }) {
		if (!linkPath) linkPath = path;	//linkPath is different with e.g. risklabels
		let field = rootGetters['field/byPath'](path);
		let value = rootGetters['product/getValue'](path);
		let baseContainer;
		if (linked === 'related-product') {
			if (!rootGetters['productlist/getDerivedProductId']) {
				throw new Error('Trying to link without derived product set.');
			}
			const derivedProduct = rootGetters['productlist/getDerivedProduct'](state.currentProductId);
			if (!derivedProduct) throw new Error('derivedProduct not loaded');
			const currentListId = state.currentList.id;
			if (derivedProduct.data.listData[currentListId]) {
				baseContainer = derivedProduct.data.listData[currentListId];
			}
		} else if (linked === 'basis') {
			let baseListId = rootGetters['list/baseDataList'].id;
			baseContainer = state.loadedData[baseListId];
		}
		if (linked !== 'direct') {
			let baseValue;
			if (baseContainer) {
				if (path.includes('components')) {
					baseValue = value;
				} else {
					baseValue = traverseToData(baseContainer, path);
				}
			}
			if (baseValue || typeof baseValue === 'boolean') {
				value = baseValue;
			} else if (field) {
				value = rootGetters['field/getDefaultValue'](field.id);
			} else {
				value = undefined;
			}
			if (typeof value === 'object') value = JSON.parse(JSON.stringify(value));
			commit('updateField', { path, value });
		}
		commit('updateLink', { path: linkPath, linked });
	}
};

const mutations = {
	/**
	 * for eu list call without baseData
	 * for other lists call with eu list as baseData
	 **/
	init(state, { baseData, listData }) {
		if (!listData) listData = {};
		state.baseData = baseData;
		state.listData = listData;
		const w = {};
		state.linked = listData._linked ? JSON.parse(JSON.stringify(listData._linked)) : {};
		initializeWorkingCopy(state.baseData, state.listData, state.linked, w);
		state.workingCopy = w;
		state.workingCopyHasChanges = false;
		EventBus.emit('afterWorkingCopyInit');
	},

	setAsId(state, { id }) {
		state.attributesetId = id;
	},

	setFields(state, f) {
		state.fields = f;
	},

	updateField(state, { path, value, noChange }) {
		// console.log('updateField', { path, value, noChange });
		let parts = path.split('.');
		let noChangeInt = deepSet(state.workingCopy, parts, value);
		noChange = noChange || noChangeInt;
		if (!noChange) {
			state.workingCopyHasChanges = true;
		}
		emitter.emitAll(path, value);
	},

	setFieldValid(state, { path, valid, message }) {
		let parts = path.split('.');
		deepSet(state.fieldErrors, parts, valid ? true : message);
	},

	updateLink(state, { path, linked }) {
		// console.log('updateLink', path, linked);
		path = sanitizeLinkPath(path);
		if (state.linked[path] === linked) return;
		Vue.set(state.linked, path, linked);
		state.workingCopyHasChanges = true;
	},

	removeTableRow(state, { key, row }) {
		let parts = key.split('.');
		let list = deepGet(state.workingCopy, parts);
		list.splice(row, 1);
		state.workingCopyHasChanges = true;
		let validList = deepGet(state.fieldErrors, parts);
		if (Array.isArray(validList)) {
			validList.splice(row, 1);
		}
		emitter.emitAll(key, list);
	},

	removeComponentRow(state, { group, id }) {
		const path = ['_components', group, id];
		deepSet(state.workingCopy, path, null);
		if (id in state.fieldErrors._components?.[group]) {
			delete state.fieldErrors._components[group][id];
		}
		state.workingCopyHasChanges = true;
		emitter.emitAll(path.join('.'), value);
	},

	setListArchived(state, { listId, archived }) {
		let entity = state.loadedData[listId];
		if (!entity) {
			return;
		}

		Vue.set(entity, '_archived', archived);
	},

	setLoadedList(state, { list, data, full }) {
		if (full) {
			state.loadedData = full;
		} else {
			if (!list.id) throw new Error(`expected list object, got ${JSON.stringify(list)}`);
			Vue.set(state.loadedData, list.id, data);
		}
	},

	changeCurrentList(state, { list }) {
		if (!list.id) throw new Error(`expected list object, got ${JSON.stringify(list)}`);
		state.currentList = list;
	},

	changeCurrentProduct(state, { id, lists }) {
		if (!Array.isArray(lists)) throw new Error('expected array of lists');
		state.currentProductId = id;
		state.lists = lists;
		state.currentList = lists[0];
		if (!state.currentList.id) throw new Error(`expected list object, got ${JSON.stringify(state.currentList)}`);
		state.loadedData = {};
	},

	setRevData(state, product) {
		state.rev = product;
	},

	setCurrentRev(state, revNo) {
		state.revNo = revNo;
	},

	setModuleInitialized(state, { root, lists }) {
		state.init = true;
		state.rootList = root;
		state.allLists = lists;
	},

	setAllLists(state, { lists }) {
		state.allLists = lists;
	},

	addList(state, { listId }) {
		if (state.lists.find(l => l.id === listId)) throw new Error(`already exists: ${listId}`);
		let list = state.allLists.find(l => l.id === listId);
		if (!list) throw new Error(`invalid listId: ${listId}`);
		state.lists.push(list);
		state.workingCopyHasChanges = true;
	},

	setWorkingCopyHasChanges(state, workingCopyHasChanges) {
		state.workingCopyHasChanges = workingCopyHasChanges;
	},

	resetFieldErrors(state) {
		state.fieldErrors = {};
	},

	closeProduct(state) {
		state.currentList = null;
		state.currentProductId = '';
		state.rev = null;
		state.revNo = null;
	},

	updateListCacheForApproal(state, { productListData }) {
		for (const [currentKey, currentListData] of Object.entries(productListData)) {
			if (state.loadedData[currentKey]) state.loadedData[currentKey]._approval = currentListData._approval;
		}
	},

	setPatch(state, patch) {
		state.patch = patch;
	},
};

export default {
	namespaced: true,
	modules,
	state,
	getters,
	actions,
	mutations
};
