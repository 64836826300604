import { updatePresenceStatus } from '@/repository/product';

const SECTION = 'equipoProducts';

const state = {
	presentUsers: []
};

const getters = {
	getPresentUsers: (state) => {
		return state.presentUsers;
	}
};

const actions = {
	async updatePresenceStatus({ state, commit, rootState, rootGetters }, id) {
		const name = rootGetters['auth/user/userName'];
		const users = await updatePresenceStatus(SECTION, name, id);
		commit('setPresentUsers', users);
	},
	async clearPresenceStatus({ state, commit, rootGetters }) {
		const name = rootGetters['auth/user/userName'];
		await updatePresenceStatus(SECTION, name);
		commit('setPresentUsers', []);
	}
};

const mutations = {
	setPresentUsers(state, users) {
		state.presentUsers = users;
	}
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
};
